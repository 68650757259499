import { useMemo } from 'react';
import { MdDirectionsWalk } from 'react-icons/md';
import { CarOutlined } from '@ant-design/icons';
import { Collapse, Divider } from 'antd';
import { MODE_LOCOMOCAO } from 'constants';
import moment from 'moment';
import { getContrast, getItinerary, getModeLocomocao, getPlanifiedRoute } from 'utils';

import CustomEmpty from 'components/elements/CustomEmpty';

const { Panel } = Collapse;
export const Itinerary = ({ routeSeller = {}, sellers_view = [], focusInLatLng = () => {} }) => {
  const getIconLocomocao = (mode) => {
    const style = { fontSize: '18px' };

    if (mode === MODE_LOCOMOCAO.CAMINHANDO) {
      return <MdDirectionsWalk style={style} />;
    }

    return <CarOutlined style={style} />;
  };

  const itinerary = useMemo(() => {
    const hashTable = {};

    Object.entries(routeSeller).forEach(([key, values]) => {
      const { checkedKeys, directionsMap: directions } = values;
      const routeInfo = getItinerary(sellers_view, checkedKeys, key, directions);

      hashTable[key] = routeInfo;
    });

    Object.entries(hashTable).forEach(([key, value]) => {
      Object.entries(value).forEach(([subkey, subvalue]) => {
        const customersByDay = subvalue;
        const planifiedRoute = getPlanifiedRoute(customersByDay);
        hashTable[key][subkey] = planifiedRoute;
      });
    });

    return hashTable;
  }, [routeSeller, sellers_view]);

  return (
    <div className='itinerary-list-container' style={{ maxHeight: '600px' }}>
      <h1 className='header-menu'>Itinerário</h1>
      <Collapse>
        {Object.entries(itinerary).map(([key, value]) => {
          const { seller_name = '', seller_code = '' } = routeSeller[key];
          return (
            <Panel header={`${seller_code} - ${seller_name}`} key={key}>
              {!routeSeller[key]['checkedKeys']?.length ? (
                <CustomEmpty
                  description='Selecione o dia que deseja visualizar'
                  context={'table'}
                />
              ) : (
                <div className='itinerary-list itinerary-roteirizze-container'>
                  {Object.entries(value).map(([key, customers]) => {
                    const [weekly_frequency, day_number] = key.split('_');
                    const day = moment().day(day_number).format('dddd');
                    return (
                      <>
                        <h2>
                          Semana ({weekly_frequency}) - {day}
                        </h2>
                        {customers.map((item, index) => {
                          const contrastColor = getContrast(item.color);
                          return (
                            <div key={item.index}>
                              <div
                                className='itinerary-client-card'
                                style={{
                                  backgroundColor: item.color,
                                  justifySelf: 'flex-end',
                                  marginTop: '6px',
                                  border: item.isParking ? '1px solid black' : null,
                                }}
                                onClick={() => {
                                  const { latitude: lat, longitude: lng } = item;
                                  focusInLatLng({ lat, lng });
                                }}
                              >
                                <div className='itinerary-roteirizze-container-service'>
                                  <div
                                    className='itinerary-client-card-circle'
                                    style={{
                                      height: 'fit-content',
                                      color: contrastColor,
                                    }}
                                  >
                                    {' '}
                                    {item.order || index + 1}º{' '}
                                  </div>
                                  <span style={{ color: contrastColor }}>{item.customer}</span>
                                </div>
                                <span style={{ color: contrastColor }}>{item.service}</span>
                              </div>

                              <div style={{ paddingLeft: '8px' }}>
                                <Divider
                                  type='vertical'
                                  dashed={item.mode_next_client === MODE_LOCOMOCAO.CAMINHANDO}
                                  style={{
                                    borderColor: '#7cb305',
                                    borderWidth: '1px',
                                  }}
                                />
                                <div
                                  style={{
                                    display: 'flex',
                                    gap: '4px',
                                    alignItems: 'center',
                                  }}
                                >
                                  {getIconLocomocao(item.mode_next_client)}

                                  <p style={{ fontSize: '14px', margin: 'unset' }}>
                                    {' '}
                                    {getModeLocomocao(item.mode_next_client)}{' '}
                                  </p>
                                </div>

                                <Divider
                                  type='vertical'
                                  dashed={item.mode_next_client === MODE_LOCOMOCAO.CAMINHANDO}
                                  style={{
                                    borderColor: '#7cb305',
                                    borderWidth: '1px',
                                  }}
                                />
                              </div>
                            </div>
                          );
                        })}
                      </>
                    );
                  })}
                </div>
              )}
            </Panel>
          );
        })}
      </Collapse>
    </div>
  );
};

export default Itinerary;
