import { useMemo } from 'react';
import { Alert, Button, Collapse, message, Switch, Tree } from 'antd';
import { max_checked_keys } from 'constants/index';

import GridValue from 'components/elements/GridValue';

import './style.css';

const { Panel } = Collapse;

export const LegendMapMenu = (props) => {
  const {
    checkedKeys,
    setShowOffRouting,
    setDirections,
    expandedKeys,
    selectedKeys,
    setExpandedKeys,
    setCheckedKeys,
    autoExpandParent,
    setAutoExpandParent,
    routeSeller,
    resetSelectedSellers,
    selectedSellers,
    lenCheckedKeys,
  } = props;

  const hasSelectedSellers = useMemo(() => {
    return Object.keys(selectedSellers || {}).length > 0;
  }, [selectedSellers]);

  const loadDirection = (checkedKeys, seller_code) => {
    setDirections?.((prev) => {
      return prev.map((direction) => {
        const visibleDirection = checkedKeys.find((checkedKey) => direction.key === checkedKey);
        return { ...direction, visible: !!visibleDirection };
      });
    }, seller_code);
  };

  const onExpand = (expandedKeysValue) => {
    setExpandedKeys(expandedKeysValue);
    setAutoExpandParent(false);
  };

  const onCheck = (checkedKeysValue, seller_code) => {
    const formatedNewCheckedKeys = checkedKeysValue.filter((value) => {
      const [weekly, day, seller_code] = value.split('_');
      return weekly && day && seller_code;
    });

    const uniqueKeys = new Set([...formatedNewCheckedKeys, ...checkedKeys]);

    if (uniqueKeys.size <= max_checked_keys) {
      setCheckedKeys(checkedKeysValue, seller_code);
      loadDirection(checkedKeysValue, seller_code);
    } else {
      message.warn('Limite de ' + max_checked_keys + ' rotas selecionadas foi atingido');
    }
  };

  const onShowRoutingOffCourse = (key) => {
    setShowOffRouting(key);
  };

  return (
    <div className='itinerary-list-container' style={{ border: 'none' }}>
      <div className='header-wrapper'>
        <h1>Roteiro</h1>
        <span
          style={{
            color:
              lenCheckedKeys === max_checked_keys
                ? 'var(--feedbacks-negative-500)'
                : 'var(--neutral-600)',
          }}
        >
          {lenCheckedKeys}/{max_checked_keys}{' '}
        </span>
      </div>
      {hasSelectedSellers && (
        <Alert
          style={{ marginBottom: 16 }}
          message="Remova os marcadores da aba 'Clientes' do mapa para melhorar a visualização dos roteiros."
          type='info'
          action={
            <Button
              className='custom-button'
              size='small'
              type='primary'
              onClick={resetSelectedSellers}
            >
              Remover
            </Button>
          }
        />
      )}
      <Collapse>
        {Object.entries(routeSeller || {}).map(([key, value]) => {
          const {
            weeklyTree = [],
            seller_name = '',
            seller_code = '',
            showOffRouting,
            checkedKeys,
            outRouteClients: outRoute = [],
          } = value || {};

          const countOutOffRoute = outRoute.length;
          return (
            <Panel header={`${seller_code} - ${seller_name}`} key={key}>
              <div className='legend-config-inputs'>
                <div className='legend-config-inputs-container'>
                  <GridValue
                    layout='horizontal'
                    label={
                      countOutOffRoute === 0
                        ? 'Sem clientes fora de rota'
                        : `Exibir Fora de Rota (${countOutOffRoute})`
                    }
                  >
                    {countOutOffRoute > 0 && (
                      <Switch
                        checked={showOffRouting}
                        className='custom-switch'
                        onChange={() => onShowRoutingOffCourse(key)}
                      />
                    )}
                  </GridValue>
                </div>
                <Tree
                  className='smart-router-tree'
                  checkable
                  onExpand={onExpand}
                  expandedKeys={expandedKeys}
                  autoExpandParent={autoExpandParent}
                  onCheck={(checked) => onCheck(checked, key)}
                  checkedKeys={checkedKeys}
                  selectedKeys={selectedKeys}
                  treeData={weeklyTree}
                  showIcon
                />
              </div>
            </Panel>
          );
        })}
      </Collapse>
    </div>
  );
};

export default LegendMapMenu;
