import { useMemo } from 'react';
import { Collapse } from 'antd';
import moment from 'moment';
import { getContrast, getRouteOverview } from 'utils/index';

import { CustomEmpty } from 'components/elements';

import './style.css';

const { Panel } = Collapse;

const locale = {
  total_distance_day: 'Distância total',
  intra_route_day: 'Distância intra rotas',
  residencia_vendedor: 'Distância da casa do agente',
  average_sale: 'Venda média',
  potential_revenue: 'Média de faturamento potencial',
  qtd_lojas: 'Qde. de lojas',
  totalDistanceWalking: 'Distancia total andando',
  totalDurationWalking: 'Tempo total andando',
};

export const RouteOverview = ({
  routeSeller = {},
  sellers_view = [],
  sellersResume = [],
  companyCode = '',
  polylines = [],
}) => {
  const routeInfo = useMemo(() => {
    const RouteOverviewBySeller = {};
    Object.entries(routeSeller).forEach(([key, values]) => {
      const { checkedKeys, directionsMap: directions } = values;
      const resumeSeller = getRouteOverview({
        sellers_view,
        checkedKeys,
        seller_code: key,
        directions,
        sellersResume,
        companyCode,
      });

      RouteOverviewBySeller[key] = resumeSeller;
    });

    Object.entries(RouteOverviewBySeller).forEach(([key, value]) => {
      Object.entries(value).forEach(([subkey, subvalue]) => {
        const aggValues = polylines
          .filter((polyline) => polyline.ref === subkey)
          .reduce(
            (acc, value) => {
              acc['totalDistanceWalking'] += value.data?.distanceWalking || 0;
              acc['totalDurationWalking'] += value.data?.timeWalking || 0;
              return acc;
            },
            { totalDistanceWalking: 0, totalDurationWalking: 0 },
          );

        RouteOverviewBySeller[key][subkey] = { ...subvalue, ...aggValues };
      });
    });

    return RouteOverviewBySeller;
  }, [companyCode, polylines, routeSeller, sellersResume, sellers_view]);

  const formatValue = (key, value = '-') => {
    if (key === 'totalDistanceWalking') {
      if (!value) {
        return '0 Km';
      }

      return (value / 1000).toFixed(2) + ' Km';
    }

    if (key === 'totalDurationWalking') {
      if (!value) {
        return '0 minutos';
      }

      return (value / 60).toFixed(2) + ' Minutos';
    }

    return value ?? '-';
  };

  return (
    <div className='itinerary-list-container' style={{ maxHeight: '600px' }}>
      <h1 className='header-menu'>Resumo da Rota</h1>
      <Collapse>
        {Object.entries(routeInfo).map(([key, value]) => {
          const { seller_code = '', seller_name = '' } = routeSeller[key];

          return (
            <Panel header={`${seller_code} - ${seller_name}`} key={key}>
              {!routeSeller[key].checkedKeys?.length ? (
                <CustomEmpty
                  description='Selecione o dia que deseja visualizar'
                  context={'table'}
                />
              ) : (
                <div className='itinerary-list itinerary-roteirizze-container'>
                  {Object.entries(value).map(([key, metric]) => {
                    const [weekly_frequency, day_number] = key.split('_');
                    const day = moment().day(day_number).format('dddd');
                    const contrastColor = getContrast(metric.color);
                    return (
                      <>
                        <h2>
                          Semana ({weekly_frequency}) - {day}
                        </h2>
                        {Object.entries(locale).map(([key, label], index) => (
                          <div
                            key={index}
                            className='itinerary-client-card'
                            style={{
                              backgroundColor: metric.color,
                            }}
                          >
                            <span
                              className='itinerary-roteirizze-container-service'
                              style={{ color: contrastColor, maxWidth: 'fit-content' }}
                            >
                              {' '}
                              {label}: {formatValue(key, metric[key])}{' '}
                            </span>
                          </div>
                        ))}
                      </>
                    );
                  })}
                </div>
              )}
            </Panel>
          );
        })}
      </Collapse>
    </div>
  );
};

export default RouteOverview;
