import { MODE_LOCOMOCAO } from 'constants';
import { v4 as uuidv4 } from 'uuid';

export const hexToRgb = (hex) => {
  hex = hex.replace(/^#/, '');

  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return [r, g, b];
};

export const getContrast = (hex) => {
  if (!hex) return undefined;

  const rgb = hexToRgb(hex);
  const brightness = (rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000;

  return brightness > 125 ? 'black' : 'white';
};

export const getItinerary = (
  sellers_view = [],
  checkedKeys = [],
  seller_code = '',
  directions = [],
) => {
  const getColor = (key) => directions.find((direction) => direction.key === key)?.color;

  const filteredCustomersSeller = sellers_view.filter(
    (seller) => seller.seller_code === seller_code,
  );
  const hashTable = {};

  filteredCustomersSeller.forEach((customer) => {
    const key = `${customer.weekly_frequency}_${customer.week_day}_${seller_code}`;
    if (checkedKeys.includes(key)) {
      const color = getColor(key);
      const formatedCustomer = {
        ...customer,
        color: !color ? 'var(--primary-600)' : color.includes('#') ? color : '#' + color,
      };
      if (hashTable[key]) {
        hashTable[key] = [...hashTable[key], formatedCustomer];
      } else {
        hashTable[key] = [formatedCustomer];
      }
    }
  });

  return hashTable;
};

export const getRouteOverview = ({
  sellers_view = [],
  checkedKeys = [],
  seller_code = '',
  directions = [],
  sellersResume = {},
  companyCode = '',
}) => {
  const compareSellerCods = (valueRef = '') => {
    return seller_code.replace(`${companyCode}.`, '') === valueRef.replace(`${companyCode}.`, '');
  };

  const calculateMetricsCustomers = (customers = [], { day_number, weekly_frequency }) => {
    const size = customers.length;
    const color = customers[0]?.color;
    const avgVendaMedia =
      customers.reduce((sum, customer) => sum + customer.average_sale, 0) / size;
    const avgFatPotencial =
      customers.reduce((sum, customer) => sum + customer.potential_revenue, 0) / size;

    const resumo =
      sellersResume.find((sv) => {
        return (
          compareSellerCods(sv.seller_code) &&
          sv.roteiro === weekly_frequency &&
          sv.day_number === (typeof day_number === 'string' ? parseInt(day_number) : day_number)
        );
      })?.resumo || {};

    const {
      intra_route_day = '-',
      percentual_residencia_vendedor = '-',
      residencia_vendedor = '-',
      total_distance_day = '-',
    } = resumo;

    return {
      intra_route_day,
      percentual_residencia_vendedor,
      residencia_vendedor,
      total_distance_day,
      qtd_lojas: size,
      average_sale: toBRL(avgVendaMedia),
      potential_revenue: toBRL(avgFatPotencial),
      color,
    };
  };

  const hashTable = getItinerary(sellers_view, checkedKeys, seller_code, directions);
  const response = {};

  Object.entries(hashTable).forEach(([key, customers]) => {
    const [weekly_frequency, day_number] = key.split('_');
    const metrics = calculateMetricsCustomers(customers, { weekly_frequency, day_number });
    response[key] = metrics;
  });

  return response;
};

export const toBRL = (value) => {
  if (!value || isNaN(value)) {
    return 'R$ 0,00';
  }

  value = parseFloat(value);

  return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
};

const inDiffFormatSellerAndSellerCode = (refSring = '', companyCode = '') => {
  if (!companyCode) {
    return false;
  }

  const includesCompanyCode = (refSring || '').includes(`${companyCode || ''}.`);
  if (includesCompanyCode) {
    return true;
  }

  return false;
};

const removeCompanyCode = (refSring = '', companyCode = '') => {
  if (!companyCode) {
    return refSring;
  }

  if (inDiffFormatSellerAndSellerCode(refSring, companyCode)) {
    return refSring.split('.')[1] ?? refSring;
  }

  return refSring;
};

export const formatSellerAndSellerCode = (data = [], companyCode = '', sellers = []) => {
  const response = [...data].map((sv) => {
    if (!sv.seller) {
      sv['seller'] = sv.seller_code;
    }

    return {
      ...sv,
      seller_code: removeCompanyCode(sv.seller_code, companyCode) || sv.seller_code,
      seller:
        sellers.find(
          (seller) => seller.seller_code === removeCompanyCode(sv.seller_code, companyCode),
        )?.name || sv.seller,
    };
  });

  return response;
};

export const formatValueToLocale = (value) =>
  new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value);

export const removeDuplicatesByKeys = (array = [], key = '') => {
  // Use apenas para array de objetos.
  const hashTable = {};

  array.forEach((element) => {
    const keyValue = element[key];
    hashTable[keyValue] = element;
  });

  return Object.values(hashTable);
};

export const getModeLocomocao = (mode = '') => {
  switch (mode) {
    case MODE_LOCOMOCAO.DIRIGINDO:
      return 'Dirigindo';
    case MODE_LOCOMOCAO.CAMINHANDO:
      return 'Caminhando';
    default:
      return 'Dirigindo';
  }
};

export const getMarkers = (directions = []) => {
  const response = {};

  const hasLocation = (obj = {}) => obj.lat && obj.lng;
  const formatedMarker = (obj = {}, key) => {
    const id = uuidv4();
    return {
      cluster: false,
      id,
      group: `${id}`,
      key,
      source: 'weekDays',
      infoContent: <div dangerouslySetInnerHTML={{ __html: obj.infoContent }} />,
      position: { lat: obj.lat, lng: obj.lng },
      icon: {
        letter: 'V',
        url: null,
        color: `#000`,
      },
    };
  };

  directions.forEach((direction) => {
    const { color = '', waypoints = [], origin = {}, destination = {}, key } = direction;

    const formatedOrigin = formatedMarker(origin, key);
    const formatedDestination = formatedMarker(destination, key);

    const markers = [
      ...(hasLocation(origin) ? [formatedOrigin] : []),
      ...waypoints.map((waypoint, index) => ({
        ...waypoint,
        key,
        group: waypoint.id,
        source: 'weekDays',
        cluster: false,
        infoContent: <div dangerouslySetInnerHTML={{ __html: waypoint.infoContent }} />,
        position: waypoint.location,
        icon: {
          letter: (index + 1).toString(),
          url: null,
          color: `#${color}`,
          stroke:
            waypoint.mode_next_client === MODE_LOCOMOCAO.CAMINHANDO &&
            waypoints[index - 1]?.mode_next_client !== waypoint.mode_next_client
              ? 'black'
              : null,
        },
      })),
      ...(hasLocation(destination) ? [formatedDestination] : []),
    ];

    response[key] = markers;
  });

  return response;
};

export const getPlanifiedRoute = (waypoints = []) => {
  let currentRoute = [];
  let startRoute = {};

  const orderedWaypoints = waypoints.map((waypoint, index) => ({ ...waypoint, order: index + 1 }));

  if (orderedWaypoints.length > 0) {
    startRoute = orderedWaypoints[0];
  }

  orderedWaypoints.forEach((currentPoint, index) => {
    const lastPoint = index > 0 ? orderedWaypoints[index - 1] : {};

    currentPoint['order'] = index + 1;

    if (
      lastPoint.mode_next_client &&
      lastPoint.mode_next_client !== currentPoint.mode_next_client
    ) {
      if (lastPoint.mode_next_client === MODE_LOCOMOCAO.CAMINHANDO) {
        currentRoute = [
          ...currentRoute,
          { ...currentPoint, mode_next_client: MODE_LOCOMOCAO.CAMINHANDO },
        ];
      } else {
        currentRoute = [...currentRoute, { ...currentPoint, isParking: true }];
      }
    } else {
      currentRoute = [...currentRoute, currentPoint];
    }

    if (
      lastPoint.mode_next_client &&
      lastPoint.mode_next_client !== currentPoint.mode_next_client
    ) {
      if (lastPoint.mode_next_client === MODE_LOCOMOCAO.CAMINHANDO) {
        currentRoute = [
          ...currentRoute,
          {
            ...startRoute,
            mode_next_client: MODE_LOCOMOCAO.DIRIGINDO,
            service: 'Retorno',
            isParking: true,
          },
        ];
      }

      startRoute = currentPoint;
    }
  });

  return currentRoute;
};

export const getAllRoutes = (waypoints = [], origin = {}, destination = {}) => {
  const routes = [];

  let currentRoute = [];
  let startRouteIndex = 0;

  const hasLocation = (obj = {}) => {
    return obj.location?.lat && obj.location?.lng;
  };

  const getFormated = (obj = {}) => ({
    location: {
      lat: obj.lat,
      lng: obj.lng,
    },
    stopover: true,
    mode_next_client: obj.mode_next_client || MODE_LOCOMOCAO.DIRIGINDO,
    infoContent: obj.infoContent,
    order: 0,
  });

  const formatedOrigin = getFormated(origin);
  const formatedDestination = getFormated(destination);

  const orderedWaypoints = [
    ...(hasLocation(formatedOrigin) ? [formatedOrigin] : []),
    ...waypoints.map((waypoint, index) => ({ ...waypoint, order: index + 1 })),
    ...(hasLocation(formatedDestination) ? [formatedDestination] : []),
  ];

  orderedWaypoints.forEach((currentPoint, index) => {
    const lastPoint = index > 0 ? orderedWaypoints[index - 1] : {};
    const { mode_next_client: lastPointMode } = lastPoint;

    currentPoint['order'] = index + 1;

    currentRoute = [...currentRoute, currentPoint];

    if (orderedWaypoints[startRouteIndex].mode_next_client !== currentPoint.mode_next_client) {
      if (lastPointMode === MODE_LOCOMOCAO.DIRIGINDO) {
        routes.push({ route: currentRoute, mode: MODE_LOCOMOCAO.DIRIGINDO });
        currentRoute = [currentPoint];
      } else if (lastPointMode === MODE_LOCOMOCAO.CAMINHANDO) {
        const firstPoint = orderedWaypoints[startRouteIndex];
        const lastPoint = firstPoint;

        const route = [...currentRoute, lastPoint];

        routes.push({ route, mode: MODE_LOCOMOCAO.CAMINHANDO });
        currentRoute = [{ ...lastPoint, mode_next_client: MODE_LOCOMOCAO.DIRIGINDO }];
      }

      startRouteIndex = index;
    }
  });

  if (currentRoute.length > 1) {
    const mode = currentRoute[0].mode_next_client;
    routes.push({ route: currentRoute, mode });
  }

  return routes;
};
