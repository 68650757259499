export const dictWeekColors = {
  0: '5E0080',
  1: 'FF80CC',
  2: 'E5DA00',
  3: '800F00',
  4: '4C4C4C',
  5: '389FFF',
  6: '7DB800',
};

export const MODE_LOCOMOCAO = {
  DIRIGINDO: 'DIRIGINDO',
  DIRIGINDO_CAMINHANDO: 'DIRIGINDO_CAMINHANDO',
  CAMINHANDO: 'CAMINHANDO',
};

export const max_checked_keys = 20;

export const F5_COOLDOWN_TIME = 2000;
