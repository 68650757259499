import { MODE_LOCOMOCAO } from 'constants';
import { dictWeekColors } from 'constants/index';
import { find, groupBy } from 'lodash';
import moment from 'moment';
import { getMarkers } from 'utils';
import { formatValueToLocale, getAllRoutes } from 'utils';

export function generateDirectionsItems(
  directionsItems,
  key,
  day,
  color,
  seller = {},
  waypoints,
  allowWalkingMode,
) {
  const origin = seller?.location || {};
  const destination = seller?.location || {};

  const infoContent =
    '<div className="d-flex">' +
    '  <div className="ml-1">' +
    '    <p><strong>Código:</strong> ' +
    seller?.seller_code +
    '</p>' +
    '    <p><strong>Vendedor:</strong> ' +
    (seller?.seller || seller?.name) +
    '</p>' +
    '  </div>' +
    '</div>';

  if (allowWalkingMode) {
    origin['infoContent'] = infoContent;
    origin['type'] = 'SELLER';

    destination['infoContent'] = infoContent;
    destination['type'] = 'SELLER';
  }

  directionsItems.push({
    key: `${key}_${day}_${seller.seller_code}`,
    visible: false,
    color,
    origin,
    destination,
    waypoints,
    ...(allowWalkingMode && {
      dinamicRoutes: allowWalkingMode ? getAllRoutes(waypoints, origin, destination) : [],
    }),
    infoContent:
      '<div className="d-flex">' +
      '  <div className="ml-1">' +
      '    <p><strong>Código:</strong> ' +
      seller.seller_code +
      '</p>' +
      '    <p><strong>Vendedor:</strong> ' +
      seller.name +
      '</p>' +
      '  </div>' +
      '</div>',
  });
}

export function genaretePoints(
  itemMarker,
  waypoints,
  items,
  color,
  letter = 'C',
  isOffRouting = false,
  onClickShowReason = () => {},
) {
  const location = {
    lat: itemMarker.latitude || 0,
    lng: itemMarker.longitude || 0,
  };

  waypoints.push({
    id: itemMarker.cod_cliente,
    cod_cliente: itemMarker.cod_cliente,
    location,
    stopover: true,
    mode_next_client: itemMarker.mode_next_client || MODE_LOCOMOCAO.DIRIGINDO,
    infoContent:
      '<div className="d-flex">' +
      '  <div className="ml-1">' +
      '    <p><strong>Código:</strong> ' +
      itemMarker.cod_cliente +
      '</p>' +
      '    <p><strong>Loja:</strong> ' +
      itemMarker.customer +
      '</p>' +
      '    <p><strong>Atendimento:</strong> ' +
      itemMarker.service +
      '</p>' +
      '    <p><strong>Frequência:</strong> ' +
      itemMarker.frequency +
      '</p>' +
      '    <p><strong>Limite de crédito:</strong> ' +
      formatValueToLocale(itemMarker.credit_limit) +
      '</p>' +
      '    <p><strong>Tempo de visitação:</strong> ' +
      itemMarker.visitation_time +
      '</p>' +
      '    <p><strong>Faturamento Potencial:</strong> ' +
      formatValueToLocale(+itemMarker.potential_revenue) +
      '</p>' +
      '    <p><strong>Venda Média:</strong> ' +
      formatValueToLocale(+itemMarker.average_sale) +
      '</p>' +
      '  </div>' +
      '</div>',
  });

  const formatReason = (reason) => {
    let weeks = [];

    if (!reason.length) {
      weeks = [{ S0: reason }];
    } else {
      weeks = groupBy(reason, 'semana');
    }

    return weeks;
  };

  items.push({
    icon: {
      letter: isOffRouting ? 'F' : letter,
      color,
    },
    id: itemMarker.cod_cliente,
    position: location,
    category: 'SELLER',
    data: itemMarker,
    showInfo: false,
    visible: true,
    infoContent: (
      <div className='d-flex'>
        <div className='ml-1'>
          <p>
            <strong>Código:</strong> {itemMarker.cod_cliente}
          </p>
          <p>
            <strong>Loja:</strong> {itemMarker.customer}
          </p>
          <p>
            <strong>Vendedor:</strong> {itemMarker.seller_code || ''} - {itemMarker.seller || ''}
          </p>
          {!isOffRouting ? (
            <p>
              <strong>Atendimento:</strong>
              {itemMarker.service}
            </p>
          ) : (
            ''
          )}
          {isOffRouting ? (
            <p>
              <strong>Frequência: </strong>
              {itemMarker.frequency}
            </p>
          ) : (
            ''
          )}
          <p>
            <strong>Limite de crédito:</strong> {formatValueToLocale(itemMarker.credit_limit)}
          </p>
          {!isOffRouting ? (
            <p>
              <strong>Tempo de visitação:</strong> {itemMarker.visitation_time}
            </p>
          ) : (
            ''
          )}
          {isOffRouting ? (
            <p>
              <strong>Faturamento Potencial:</strong>{' '}
              {formatValueToLocale(itemMarker.potential_revenue)}
            </p>
          ) : (
            ''
          )}
          {isOffRouting ? (
            <p>
              <strong>Venda Média:</strong> {formatValueToLocale(itemMarker.average_sale)}
            </p>
          ) : (
            ''
          )}
          <p>
            <strong>Status: </strong> {isOffRouting ? 'Fora de rota' : 'Em rota'}
          </p>
          {isOffRouting && (
            <button
              className='custom-button ant-btn-primary ant-btn-background-ghost'
              onClick={() => {
                const reason = formatReason(JSON.parse(itemMarker.reason));
                onClickShowReason(reason);
              }}
            >
              Motivo(s)
            </button>
          )}
        </div>
      </div>
    ),
  });
}

export function generateSellerMarker(seller) {
  const { location, seller_code } = seller;

  const marker = {
    icon: {
      letter: 'V',
      color: '#000',
    },
    id: seller_code,
    position: location,
    category: 'SELLER',
    data: seller,
    showInfo: false,
    visible: true,
    infoContent: (
      <div className='d-flex'>
        <div className='ml-1'>
          <p>
            <strong>Código: </strong>
            {seller.seller_code}
          </p>
          <p>
            <strong>Vendedor: </strong>
            {seller.name}
          </p>
        </div>
      </div>
    ),
  };
  return marker;
}

export function generateClientsMarkers({
  clients = [],
  color,
  label,
  inRoute: defaultInRoute,
  onClickShowReason = () => {},
}) {
  let markers = [];
  let waypoints = [];

  clients.forEach((client) => {
    const { inRoute = defaultInRoute } = client;

    genaretePoints(client, waypoints, markers, color, label, !inRoute, onClickShowReason);
  });

  return markers;
}

export function parseMapData({
  customers = [],
  seller = {},
  routingOffCourse = [],
  sellers = [],
  onClickShowReason = () => {},
  allowWalkingMode = false,
}) {
  let items = [];
  let treeData = [];
  let directionsItems = [];

  const weeklyFrequency = groupBy(customers, 'weekly_frequency');
  Object.keys(weeklyFrequency).forEach((key) => {
    const days = groupBy(weeklyFrequency[key], 'week_day');

    treeData.push({
      key: `${key}_${seller.seller_code}`,
      title: `Semana (${key})`,
      children: Object.keys(days).map((day) => {
        const color = dictWeekColors[day];
        let waypoints = [];

        days[day].forEach((itemMarker) => {
          genaretePoints(itemMarker, waypoints, items, color);
        });

        generateDirectionsItems(
          directionsItems,
          key,
          day,
          color,
          seller,
          waypoints,
          allowWalkingMode,
        );

        return {
          key: `${key}_${day}_${seller.seller_code}`,
          icon: (
            <div
              style={{
                height: '16px',
                width: '10px',
                borderRadius: '6px',
                background: '#' + color,
              }}
            ></div>
          ),
          title: (
            <div>
              <span
                style={{
                  color: 'var(--neutral-900)',
                }}
              >
                {moment().set('day', day).format('dddd')}
              </span>
            </div>
          ),
          color,
        };
      }),
    });
  });

  let waypoints = [];
  let foraRotas = [];

  routingOffCourse?.forEach((itemMarker) => {
    genaretePoints(itemMarker, waypoints, foraRotas, '#000', '*', true, onClickShowReason);
  });

  const findedSellerInSellerInfo = find(sellers, { seller_code: customers[0].seller_code });

  if (allowWalkingMode) {
    const hashTable = getMarkers(directionsItems);
    directionsItems = directionsItems.map((direction) => ({
      ...direction,
      markers: hashTable[direction.key],
    }));
  }

  return { findedSellerInSellerInfo, waypoints, foraRotas, treeData, directionsItems };
}

export function getRandomHexColor() {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}
